import moment from "moment/moment";

export const getStartOfWeek = (date: Date) => {
    let currentWeekDayNumber = date.getDay();
    if (currentWeekDayNumber === 0) { // 0 for sunday
        currentWeekDayNumber = 7;
    }

    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1 - currentWeekDayNumber);
    return newDate;
};

export const addDaysToDate = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const getEndOfWeek = (date: Date) => {
    return addDaysToDate(getStartOfWeek(date), 6);
};

export const getStartOfNextWeek = (date: Date) => {
    return addDaysToDate(getStartOfWeek(date), 7);
};

export const getWeekDayName = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {weekday: "long"};
    return date.toLocaleDateString('ru-RU', options);
}

export const getDayOfMonth = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {day: 'numeric', month: "long"};
    return date.toLocaleDateString('ru-RU', options);
}

export const formatMinutes = (minutes: number) => {
    const mins = minutes % 60;
    const hours = Math.floor(minutes / 60);
    if (hours === 0) {
        return `${minutes} мин.`;
    }

    if (mins === 0) {
        return `${hours} ч.`;
    }

    return `${hours} ч. ${mins} мин.`;
}

export const formatDate = (date: string | Date) => {
    return moment(date).format('DD.MM.YYYY');
}

export const formatTime = (dateTime: string) => {
    return moment(dateTime).format('HH:mm');
};