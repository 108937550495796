import {ReactNode} from "react";
import {Typography} from "@mui/material";

export const [bmiMin, bmiMax]: [number, number] = [12, 31.5];

export interface CalcCaloriesAndBmiParams {
    weight: number;
    height: number;
    age: number;
    sex: 'male' | 'female';
    activityLevel: 'verylow' | 'low' | 'mid' | 'high' | 'veryhigh';
    goal: 'drop' | 'maintain' | 'gain';
}

export interface CalcCaloriesAndBmiResult {
    kcal: number;
    bmi: number;
    proteins: number;
    fats: number;
    carbs: number;
}

export const calcCaloriesAndBmi = (
    {
        weight,
        height,
        age,
        sex,
        activityLevel,
        goal
    }: CalcCaloriesAndBmiParams
): CalcCaloriesAndBmiResult => {
    const genderAdditionalValue: number = sex === 'male' ? 5 : -161;
    const dayActivityLevelCoefficients: { [key: string]: number } = {
        'verylow': 1.2,
        'low': 1.375,
        'mid': 1.55,
        'high': 1.725,
        'veryhigh': 1.9
    };

    const goalCoefficients: { [key: string]: number } = {
        'drop': 0.8,
        'maintain': 1,
        'gain': 1.2
    };

    const kcal: number = ((10 * weight) + (6.25 * height) - (5 * age) + genderAdditionalValue) * dayActivityLevelCoefficients[activityLevel] * goalCoefficients[goal];
    const proteins: number = 0.15 * kcal / 4;
    const fats: number = 0.3 * kcal / 9;
    const carbs: number = 0.55 * kcal / 4;
    const heightM: number = height / 100;
    let bmi: number = weight / (heightM * heightM);
    if (bmi > bmiMax) {
        bmi = bmiMax;
    }

    if (bmi < bmiMin) {
        bmi = bmiMin;
    }

    return {
        kcal,
        bmi,
        proteins,
        fats,
        carbs
    };
};

export interface BmiPhraseResult {
    title: string;
    type: 'warning' | 'success';
    description: ReactNode;
}

export const getBmiPhrase = (bmi: number): BmiPhraseResult => {
    if (bmi < 18.5) {
        return {
            title: `Ваш Индекс массы тела ${bmi} - ниже нормы`,
            type: 'warning',
            description:
                <Typography variant="body1" fontSize="15px">
                    Нормальным ИМТ считается показатель от 18,5 до 24,9.<br/><br/>
                    Пониженный индекс массы тела имеет следующие риски:
                    <ul>
                        <li>Дефицит витаминов и минералов</li>
                        <li>Ослабление иммунитета</li>
                        <li>Гормональный сбой (проблемы с циклом у женщин)</li>
                        <li>Потеря мышечной массы и слабость</li>
                    </ul>
                    Приложение учитывает показатель ИМТ для подбора рецептов и может помочь в его нормализации.<br/>
                    Если у вас есть проблемы со здоровьем или опасения, проконсультируйтесь с врачом перед применением рациона.
                </Typography>
        }
    }

    if (bmi < 24.5) {
        return {
            title: `Ваш Индекс массы тела ${bmi} - в норме`,
            type: 'success',
            description:
                <Typography variant="body1" fontSize="15px">
                    Ваш ИМТ считается нормальным (показатель нормы - от 18,5 до 24,9). <br/><br/>
                    Приложение может помочь поддерживать ваш показатель на нормальном уровне.
                </Typography>
        }
    }

    return {
        title: `Ваш Индекс массы тела ${bmi} - избыточный вес`,
        type: 'warning',
        description:
            <Typography variant="body1" fontSize="15px">
                Нормальным ИМТ считается показатель от 18,5 до 24,9.<br/><br/>
                Повышенный индекс массы тела имеет следующие риски:
                <ul>
                    <li>Риск сердечно-сосудистых заболеваний (инфаркт, инсульт)</li>
                    <li>Сахарный диабет 2 типа</li>
                    <li>Проблемы с суставами и позвоночником</li>
                    <li>Гормональные нарушения</li>
                </ul>
                Приложение учитывает показатель ИМТ для подбора рецептов и может помочь в его нормализации.<br/>
                Если у вас есть проблемы со здоровьем или опасения, проконсультируйтесь с врачом перед применением рациона.
            </Typography>
    }
};