import React, {FC} from 'react';
import Stack from "@mui/material/Stack";
import {
    Box,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addAnswer, AnswerType, QuestionData} from "../../store/quizSlice";
import {Transitions} from "../../components/transitions";
import {validateNumber} from "../../helpers/validation";
import {
    QuestionTypeSelect, QuestionTypeInput, QuestionTypeChips, OverallQuestionsCount, questionsData
} from "../../data/quiz";
import {NumberInputQuestion} from "./components/numberInputQuestion";
import {QuizHeader} from "./components/header";
import {SelectQuestion} from "./components/selectQuestion";
import {ChipsQuestion} from "./components/chipsQuestion";
import {sendEndedQuiz, sendShownQuestion} from "../../helpers/analytics";
import {ROUTES, useAppNavigate} from "../../helpers/routing";
import {useTypedSelector} from "../../store/store";
import AppContainer from "../../components/appContainer";

interface QuestionProps {
    question: QuestionData;
    questionNum: number;
    answer: AnswerType;
    onNavigateNext: () => void;
}

const Question: FC<QuestionProps> = ({question, questionNum, answer, onNavigateNext}) => {
    const dispatch = useDispatch();

    const handleOnNext = (answer: any) => {
        dispatch(addAnswer({
            questionNum,
            answer: answer
        }));

        onNavigateNext();
    };

    switch (question.type) {
        case QuestionTypeSelect:
            return <SelectQuestion key={questionNum}
                                   options={question.options!}
                                   initialAnswer={answer || []}
                                   isMultiSelect={question.isMultiSelect!}
                                   onNextClick={handleOnNext}/>;
        case QuestionTypeInput:
            return <NumberInputQuestion key={questionNum}
                                        autoFocus={true}
                                        suffix={question.suffix ?? ""}
                                        initialValue={answer || ''}
                                        validationFunc={(value: string) => validateNumber(Number(value), question.validation!)}
                                        onNextClick={handleOnNext}/>;
        case QuestionTypeChips:
            return <ChipsQuestion key={questionNum}
                                  chips={question.chips!}
                                  initialAnswer={answer || []}
                                  onNextClick={handleOnNext}/>;
        default:
            throw new DOMException(`undefined question type ${question.type}`);
    }
};

const QuizPage: FC = () => {
    const state = useTypedSelector(s => s.quiz);
    const {questionNum: questionNumStr} = useParams();
    const questionNum = Number(questionNumStr);
    const navigate = useAppNavigate();
    const question = questionsData[questionNum];
    const answer = state.answers[questionNum];

    const handleNextQuestion = () => {
        const questionNumber = questionNum + 1;
        if (questionNumber > OverallQuestionsCount) {
            navigate(ROUTES.LOADING_PLAN);
            sendEndedQuiz();
            return;
        }

        navigate(ROUTES.QUIZ_QUESTION(questionNumber));
        sendShownQuestion(questionNumber);
    };

    const handlePrevQuestion = () => {
        const questionNumber = questionNum - 1;
        if (questionNumber < 1) {
            navigate(ROUTES.MAIN_PAGE);
            return;
        }

        navigate(ROUTES.QUIZ_QUESTION(questionNumber));
    };

    return (
        <Transitions key="quiz">
            <AppContainer disableLogo disableFooter>
                <Stack direction="column">
                    <Stack direction="column" mt={1}>
                        <QuizHeader questionNum={questionNum} questionCount={OverallQuestionsCount}
                                    onBackClick={() => handlePrevQuestion()}/>
                        <Typography variant="h4" component="div" mt={2}>
                            {question.text}
                        </Typography>

                        <Box mt={5}>
                            <Question question={question}
                                      questionNum={questionNum}
                                      answer={answer}
                                      onNavigateNext={handleNextQuestion}/>
                        </Box>
                    </Stack>
                </Stack>
            </AppContainer>
        </Transitions>
    );
};

export default QuizPage;