import {useEffect, useState, FC, memo, useCallback} from "react";
import {Box, CircularProgress, Grow, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {ROUTES, useAppNavigate} from "../helpers/routing";
import AppContainer from "../components/appContainer";
import {Transitions} from "../components/transitions";

interface CircularProgressFilledProps {
    onFinishProgress: () => void;
    onNextStepProgress: (stepNum: number) => void;
}

const CircularProgressFilled = memo(({onFinishProgress, onNextStepProgress}: CircularProgressFilledProps) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration: number = 12_000;
        const intervalTime: number = 100;
        const totalSteps: number = duration / intervalTime;
        const step: number = 100 / totalSteps;

        let currentProgress: number = 0;
        const interval: NodeJS.Timeout = setInterval(() => {
            currentProgress += step;
            setProgress(Math.min(currentProgress, 100));

            if (currentProgress >= 100) {
                onFinishProgress();
                clearInterval(interval);
            }
        }, intervalTime);

        const phrasesCount = 6;
        const phraseIntervalTime = duration / phrasesCount;
        let phraseProgress = 0;
        const phraseInterval = setInterval(() => {
            phraseProgress += 1;
            onNextStepProgress(phraseProgress);
        }, phraseIntervalTime);

        return () => {
            clearInterval(interval);
            clearInterval(phraseInterval);
        };
    }, [onFinishProgress, onNextStepProgress]);

    return (
        <Box sx={{
            position: 'relative'
        }}>
            <CircularProgress color="secondary" variant="determinate" value={progress} size="150px"/>
            <Typography variant="h6" fontSize="26pt"
                        sx={{
                            position: 'absolute',
                            bottom: '33%',
                            left: '25%'
                        }}>
                {`${Math.round(progress)}%`}
            </Typography>
        </Box>
    );
});

const LoadingPlanPage = () => {
    const [stepVisible, setStepVisible] = useState(0);
    const navigate = useAppNavigate();
    const handleOnFinishProgress = useCallback(() => {
        setTimeout(() => navigate(ROUTES.SUBSCRIBE), 1500);
    }, []);

    const handleOnNextStepProgress = useCallback((progress: number) => {
        setStepVisible(progress);
    }, []);

    return (
        <AppContainer disableFooter>
            <Transitions key="loadingPlan">
                <Stack direction="column" mt={10}>
                    <Stack direction="column"
                           alignItems="center"
                           spacing={2}
                           sx={{
                               margin: 'auto'
                           }}>
                        <CircularProgressFilled onFinishProgress={handleOnFinishProgress}
                                                onNextStepProgress={handleOnNextStepProgress}/>

                        <Steps index={stepVisible}/>
                    </Stack>
                </Stack>
            </Transitions>
        </AppContainer>
    );
};

const Steps = ({index}: { index: number }) => {
    const phrases = [
        "Анализируем ваши ответы...",
        "Рассчитываем ИМТ...",
        "Рассчитываем норму калорий...",
        "Подбираем рецепты, которые могут вам понравиться...",
        "Вкусные и полезные идеи уже на подходе...",
        "Формируем результат..."
    ];

    return (<>
        {phrases.map(((p, i) => (
            <Grow in={index > i}>
                <Typography variant="body1" fontSize="22px">
                    {p}
                </Typography>
            </Grow>
        )))}
    </>)
};

export default LoadingPlanPage;