import {
    QuestionActivity,
    QuestionAge,
    QuestionDaysWithRepetition,
    QuestionEstimatedMinutes,
    QuestionExcludedCategories,
    QuestionExperience, QuestionGoal, QuestionHeight,
    QuestionIngredientExperiments,
    QuestionIngredientPreferences,
    QuestionMealExperiments,
    QuestionMealTypes,
    QuestionPersons, QuestionSex, QuestionWeight
} from "../data/quiz";
import {AnswersType, AnswerType} from "../store/quizSlice";
import {
    CerealPreferences,
    Difficulty,
    FishPreferences,
    Frequency,
    IngredientCategory, MealType, MealTypePreferences,
    MeatPreferences,
    MenuPreferences,
    SpicyPreferences
} from "../api/types";
import {calcCaloriesAndBmi, CalcCaloriesAndBmiParams} from "./bmiCalc";

export const mapAnswersToMenuPreferences = (answers: AnswersType): MenuPreferences => {
    const calcParams: CalcCaloriesAndBmiParams = {
        weight: Number(answers[QuestionWeight]),
        height: Number(answers[QuestionHeight]),
        age: Number(answers[QuestionAge]),
        sex: answers[QuestionSex] as any,
        activityLevel: answers[QuestionActivity] as any,
        goal: answers[QuestionGoal] as any
    };

    const {kcal} = calcCaloriesAndBmi(calcParams);
    return {
        excludedIngredientCategories: mapIngredientCategories(answers[QuestionExcludedCategories]),
        ingredientsPreferences: {
            meatPreferences: mapIngredientPreferences<MeatPreferences>("meat", answers[QuestionIngredientPreferences]),
            fishPreferences: mapIngredientPreferences<FishPreferences>("fish", answers[QuestionIngredientPreferences]),
            cerealPreferences: mapIngredientPreferences<CerealPreferences>("cereal", answers[QuestionIngredientPreferences]),
            spicyPreferences: mapIngredientPreferences<SpicyPreferences>("spicy", answers[QuestionIngredientPreferences])
        },
        nutrientPreferences: {
            kcal: kcal,
            proteinsPercentOfDv: 15,
            fatsPercentOfDv: 30,
            carbsPercentOfDv: 55
        },
        mealTypes: (answers[QuestionMealTypes] as string[]).map((a: string) => ({mealType: a as unknown as MealType} as MealTypePreferences)),
        personsAdjustment: mapNumber(answers[QuestionPersons]),
        estimatedMinutesMax: mapEstimatedMinutes(answers[QuestionEstimatedMinutes]),
        difficultyMax: mapDifficulty(answers[QuestionExperience]),
        mealExperimentsFrequency: mapExperimentsAns(answers[QuestionMealExperiments]),
        ingredientsExperimentsFrequency: mapExperimentsAns(answers[QuestionIngredientExperiments]),
        daysWithRepetition: mapNumber(answers[QuestionDaysWithRepetition])
    } as MenuPreferences;
};

const mapIngredientCategories = (categories: AnswerType): IngredientCategory[] => {
    return categories as unknown as IngredientCategory[] || [];
}

const mapIngredientPreferences = <T>(prefix: string, preferences: AnswerType): T[] => {
    return (preferences as string[])?.filter((a: string) => a.indexOf(`${prefix}_`) !== -1).map((a: string) => a.replace(`${prefix}_`, "")) as unknown as T[] || [];
}

const mapEstimatedMinutes = (ans: AnswerType): number => {
    ans = ans as string[];

    if (ans.includes('half_hour')) {
        return 30;
    }

    if (ans.includes('hour')) {
        return 60;
    }

    if (ans.includes('hour_and_half')) {
        return 120;
    }

    if (ans.includes('not_matter')) {
        return -1;
    }

    return -1;
};

const mapDifficulty = (ans: AnswerType): Difficulty => {
    ans = ans as string[];

    if (ans.includes('newbie')) {
        return Difficulty.Easy;
    }

    if (ans.includes('experienced')) {
        return Difficulty.Medium;
    }

    if (ans.includes('advanced')) {
        return Difficulty.Hard;
    }
    return Difficulty.Unknown;
};

const mapExperimentsAns = (ans: AnswerType): Frequency => {
    ans = ans as string[];

    if (ans.includes('often')) {
        return Frequency.Often;
    }

    if (ans.includes('sometimes')) {
        return Frequency.Sometimes;
    }

    if (ans.includes('rarely')) {
        return Frequency.Rarely;
    }

    return Frequency.Unknown;
};

const mapNumber = (ans: AnswerType): number => {
    ans = ans as string[];

    if (ans.includes('one')) {
        return 1;
    }

    if (ans.includes('two')) {
        return 2;
    }

    if (ans.includes('three')) {
        return 3;
    }

    if (ans.includes('four')) {
        return 4;
    }

    if (ans.includes('five')) {
        return 5;
    }

    if (ans.includes('six')) {
        return 6;
    }

    return 2;
};