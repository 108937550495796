import {memo, useEffect} from "react";
import {Box} from "@mui/material";
import Stack from "@mui/material/Stack";
import {VkTokenExchangeParams} from "../api/types";

export enum OAuthType {
    Google, Yandex, VK
}

export interface OAuthButtonsProps {
    onSuccess: (type: OAuthType, accessToken: string | VkTokenExchangeParams) => void;
    onFailure: (type: OAuthType, error: any) => void;
}

export const OAuthButtons = memo(({onSuccess, onFailure}: OAuthButtonsProps) => {
    useYandexOauth({
        onSuccess: response => onSuccess(OAuthType.Yandex, response),
        onFailure: error => onFailure(OAuthType.Yandex, error)
    });

    useGoogleAuth({
        onSuccess: response => onSuccess(OAuthType.Google, response),
    });

    useVkAuth({
        onSuccess: token => onSuccess(OAuthType.VK, token),
        onFailure: error => onFailure(OAuthType.VK, error)
    });

    return (
        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
            <GoogleOAuthButton/>
            <YandexOauthButton/>
            <VkOAuthButton/>
        </Stack>
    );
});

interface GoogleAuthProps {
    onSuccess: (accessToken: string) => void;
}

const useGoogleAuth = ({onSuccess}: GoogleAuthProps) => {
    const handleCredentialResponse = (response: any) => {
        onSuccess(response.credential);
    };

    useEffect(() => {
        // @ts-ignore
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            callback: handleCredentialResponse
        });

        const buttonElement = document.getElementById('googleAuth');
        // @ts-ignore
        window.google.accounts.id.renderButton(buttonElement, {
            type: 'standard',
            theme: 'filled_black',
            size: 'large',
            text: 'signup_with',
            shape: 'pill',
            width: '365'
        });
    }, []);
};

const GoogleOAuthButton = () => {
    return (
        <Box id="googleAuth"/>
    );
};

interface VkAuthProps {
    onSuccess: (token: VkTokenExchangeParams) => void;
    onFailure: (error: any) => void;
}

const useVkAuth = ({onSuccess, onFailure}: VkAuthProps) => {
    const vkError = (error: any) => {
        onFailure(error);
    };

    const generateCodeVerifier = (): string => {
        const chars = 'abcdefghijklmnopqrstuvwxyz';
        const length = 43;
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            randomString += chars[randomIndex];
        }

        return randomString;
    };

    useEffect(() => {
        if ('VKIDSDK' in window) {
            const VKID: any = window.VKIDSDK;

            const codeVerifier = generateCodeVerifier();
            VKID.Config.init({
                app: process.env.REACT_APP_VK_APP_ID,
                redirectUrl: process.env.REACT_APP_VK_REDIRECT_URI,
                responseMode: VKID.ConfigResponseMode.Callback,
                source: VKID.ConfigSource.LOWCODE,
                scope: 'vkid.personal_info email',
                codeVerifier: codeVerifier,
            });

            const oneTap = new VKID.OneTap();
            const element = document.getElementById('vkAuth');
            oneTap.render({
                container: element,
                showAlternativeLogin: true,
                oauthList: [
                    'ok_ru',
                    'mail_ru'
                ]
            })
                .on(VKID.WidgetEvents.ERROR, vkError)
                .on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, (payload: any) => {
                    const code = payload.code;
                    const deviceId = payload.device_id;
                    const state = payload.state;
                    onSuccess({
                        deviceId,
                        state,
                        codeVerifier,
                        code,
                        clientId: process.env.REACT_APP_VK_APP_ID!!,
                        redirectUri: process.env.REACT_APP_VK_REDIRECT_URI!!
                    });
                });
        }
    }, []);
};

const VkOAuthButton = () => {
    return (
        <Box id="vkAuth" width="100%"/>
    )
};

interface YandexAuthProps {
    onSuccess: (token: string) => void;
    onFailure: (error: any) => void;
}

const useYandexOauth = ({onSuccess, onFailure}: YandexAuthProps) => {
    useEffect(() => {
        // @ts-ignore
        window.YaAuthSuggest.init({
                client_id: process.env.REACT_APP_YANDEX_OAUTH_CLIENT_ID,
                response_type: 'token',
                redirect_uri: process.env.REACT_APP_YANDEX_OAUTH_REDIRECT_URI
            },
            process.env.REACT_APP_YANDEX_OAUTH_REDIRECT_ORIGIN,
            {
                view: 'button',
                parentId: 'yandexAuth',
                buttonView: 'main',
                buttonTheme: 'light',
                buttonSize: 'm',
                buttonBorderRadius: 20
            }
        )
            // @ts-ignore
            .then((result) => {
                return result.handler()
            })
            // @ts-ignores
            .then((data) => {
                onSuccess(data.access_token);
            })
            // @ts-ignore
            .catch((error) => {
                onFailure(error);
            });
    }, []);
};

const YandexOauthButton = () => {
    return (
        <Box id="yandexAuth" width="100%"/>
    );
};