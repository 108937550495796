import {useLayoutEffect, useRef} from "react";

export const useScriptsFromHtml = (html: string | undefined) => {
    useLayoutEffect(() => {
        if (!html) {
            return;
        }

        const container = document.createElement("div");
        container.innerHTML = html;

        const scripts = container.querySelectorAll("script");
        const newScripts: HTMLScriptElement[] = [];
        scripts.forEach((script) => {
            const newScript = document.createElement("script");
            if (script.src) {
                newScript.src = script.src;
            } else {
                newScript.textContent = script.textContent;
            }

            newScript.type = script.type || "text/javascript"; // Устанавливаем тип
            document.body.appendChild(newScript);
            newScripts.push(newScript);
        });

        return () => {
            newScripts.forEach((script) => {
                document.body.removeChild(script);
            });
        };
    }, [html]);
};