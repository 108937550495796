import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {questionsData} from "../data/quiz";

export type AnswerType = string[] | string | number | undefined;
export type AnswersType = Record<string, AnswerType>;

export interface QuestionOption {
    text: string;
    name: string;
    subtext?: string;
    icon?: string;
}

export interface QuestionValidation {
    from: number;
    to: number;
}

export interface QuestionData {
    type: string;
    text: string;
    suffix?: string;
    options?: QuestionOption[];
    isMultiSelect?: boolean;
    validation?: QuestionValidation;
    chips?: QuestionOption[];
}

export interface QuizState {
    answers: AnswersType;
}

export interface AddAnswerPayload {
    questionNum: number;
    answer: AnswerType;
}

export const quizSlice = createSlice({
    name: 'quiz',
    initialState: {
        answers: {} as AnswersType
    } as QuizState,
    reducers: {
        addAnswer: (state, action: PayloadAction<AddAnswerPayload>) => {
            const {questionNum, answer} = action.payload;

            return {
                ...state,
                answers: {
                    ...state.answers,
                    [questionNum]: answer
                }
            };
        },
    },
});

export const {addAnswer} = quizSlice.actions;
export default quizSlice.reducer;