import Stack from "@mui/material/Stack";
import {Box, Breakpoint, Container, Typography} from "@mui/material";
import React from "react";
import Item from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2";
import {ScrollRestoration} from "react-router-dom";

export interface AppContainerProps {
    children?: React.ReactNode;
    disableLogo?: boolean;
    disableFooter?: boolean;
    footerSpace?: boolean;
    maxWidth?: Breakpoint;
}

const AppContainer = ({children, disableLogo, disableFooter, footerSpace, maxWidth}: AppContainerProps) => {
    return (
        <Container maxWidth={maxWidth || 'xs'}
                   sx={{
                       p: 0
                   }}>
            <ScrollRestoration/>

            <Stack direction="column" height="100vh">
                {!disableLogo &&
                    <Item className="QuizHeader-brandLogo" mt={2} href="https://cookhaus.guru"
                          component="a">CookHaus</Item>
                }

                <Box mt={2}
                     pl={2}
                     pr={2}
                     sx={{
                         flex: 1,
                         borderRadius: 0
                     }}>
                    {children}
                </Box>

                {!disableFooter && <ContactLinks/>}
                {footerSpace && <div className="App-footerSpace"/>}
            </Stack>
        </Container>
    )
};

export const ContactLinks: React.FC = () => (
    <Grid2 container
           justifyContent="center"
           rowSpacing={2}
           mb={1}
           sx={{
               '& a': {
                   color: '#97A2B0',
                   textDecoration: 'none'
               }
           }}>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/terms_of_use">Пользовательское соглашение</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="https://cookhaus.guru/privacy_policy">Политика конфиденциальности</a>
        </Grid2>
        <Grid2 xs={6}>
            <a href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>
        </Grid2>
    </Grid2>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReCaptchaNotice = () => {
    return (
        <>
            <Grid2 xs={12}>
                <Typography fontSize="12px" color="gray">
                    Сайт использует защиту от Google reCAPTCHA и к нему применяются <a
                    href="https://policies.google.com/privacy">Политика конфиденциальности</a> и <a
                    href="https://policies.google.com/terms">Правила использования</a> Google.
                </Typography>
            </Grid2>
        </>
    );
};

export default AppContainer;