import Stack from "@mui/material/Stack";
import {Box, Button, Container, Typography} from "@mui/material";
import {Transitions} from "../components/transitions";
import {ROUTES, useAppNavigate} from "../helpers/routing";
import React, {useCallback} from "react";
import AppContainer from "../components/appContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import {isAndroidOS} from "../helpers/isAndroid";
import {sendShownQuestion, sendStartedQuiz} from "../helpers/analytics";

interface HowToItemParams {
    img: string;
    text: string;
}

const HowToItem = ({img, text}: HowToItemParams) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Box component="img"
                 src={`/assets/quiz_first/${img}`}
                 width="50px"
                 height="50px"/>

            <Typography variant="body2" fontSize="22px" textAlign="left">
                {text}
            </Typography>
        </Stack>
    );
};

const IntroPage = () => {
    const navigate = useAppNavigate();
    const handleNext = useCallback(() => {
        if (isAndroidOS()) {
            sendShownQuestion(1);
            sendStartedQuiz();
            navigate(ROUTES.QUIZ_QUESTION(1));
            return;
        }

        navigate(ROUTES.OS_SELECT);
    }, [navigate]);

    return (
        // @ts-ignore
        <Transitions className="transition-h100">
            <AppContainer disableLogo maxWidth="md">
                <div className="pw-secondaryBlock">
                    <Container maxWidth="sm" sx={{p: 0}}>
                        <Stack direction="column"
                               alignItems="center"
                               className="secondary-background-color"
                               sx={{p: 2, borderRadius: 3}}>
                            <Box sx={{position: 'relative', display: 'inline-block'}} component="div">
                                <Box
                                    id="image"
                                    component="img"
                                    src="/assets/logo_quiz.png"
                                    sx={{display: 'block'}}
                                />

                                <Box
                                    id="particles"
                                    component="img"
                                    src="/assets/animation_particles.gif"
                                    sx={{position: 'absolute', top: '-20%', left: 0, display: 'block'}}
                                />
                            </Box>


                            <Typography variant="h1"
                                        textAlign="center"
                                        mt={3}
                                        sx={{
                                            fontSize: {
                                                xs: '28px',
                                                sm: '32px'
                                            }
                                        }}>
                                Получайте подобранные под <span
                                className="brand-secondary-color">ваши предпочтения</span> рецепты и экономьте время
                            </Typography>

                            <Typography variant="body1"
                                        textAlign="center"
                                        mt={2}
                                        sx={{
                                            fontSize: {
                                                xs: '20px',
                                                sm: '24px'
                                            }
                                        }}>
                                Пройдите небольшой опрос, который поможет определить ваши предпочтения. Потом приложение
                                проанализирует их и подберет подходящие для вас блюда
                            </Typography>

                            <Button variant="contained"
                                    color="primary"
                                    sx={{
                                        color: 'white',
                                        mt: 3,
                                        textTransform: 'none',
                                        borderRadius: 2,
                                        fontSize: {
                                            xs: '16px',
                                            sm: '20px'
                                        }
                                    }}
                                    size="large"
                                    onClick={handleNext}
                                    fullWidth={true}>
                                Давайте попробуем!
                            </Button>
                        </Stack>
                    </Container>
                </div>

                <Stack direction="column" mt={3}>
                    <Typography variant="h1" fontSize="32px">
                        Как мы подбираем рецепты?
                    </Typography>

                    <Box mt={3}/>
                    <Grid2 container
                           columns={{
                               xs: 1,
                               sm: 2
                           }}
                           rowSpacing={3}
                           columnSpacing={2}>
                        <Grid2 xs={1}>
                            <HowToItem img="customer-satisfaction.png"
                                       text="Учитывая ваши любимые и нелюбимые продукты"/>
                        </Grid2>

                        <Grid2 xs={1}>
                            <HowToItem img="clock.png" text="Исходя из времени, которое вы готовы тратить на готовку"/>
                        </Grid2>

                        <Grid2 xs={1}>
                            <HowToItem img="serving-dish.png"
                                       text="Разнообразно: вы сами указываете, как часто блюдо может повторяться. Эксперементируйте с новыми продуктами и рецептами так часто, как вам нужно"/>
                        </Grid2>

                        <Grid2 xs={1}>
                            <HowToItem img="chef.png"
                                       text="С учетом ваших кулинарных навыков: в приложении найдутся рецепты как для новичков, так и для более опытных кулинаров"/>
                        </Grid2>
                    </Grid2>

                    <Button variant="contained"
                            color="secondary"
                            sx={{
                                color: 'white',
                                mt: 3,
                                textTransform: 'none',
                                borderRadius: 2,
                                fontSize: {
                                    xs: '16px',
                                    sm: '20px'
                                }
                            }}
                            size="large"
                            onClick={handleNext}>
                        Начать опрос
                    </Button>
                </Stack>

                <Stack direction="column" mt={3}>
                    <Typography variant="h1" fontSize="32px">
                        Как это поможет мне экономить время?
                    </Typography>

                    <Grid2 container
                           mt={3}
                           alignItems="center"
                           columnSpacing={2}
                           columns={{
                               xs: 1,
                               sm: 2
                           }}>
                        <Grid2 xs={1}>
                            <Box component="img"
                                 src="/assets/quiz_first/illustration2.jpeg"
                                 maxWidth="100%"
                                 sx={{borderRadius: 3}}/>
                        </Grid2>

                        <Grid2 xs={1}>
                            <Typography variant="body1"
                                        textAlign="center"
                                        mt={2}
                                        fontSize="20px">
                                Многие из нас, едва ли ни ежедневно, сталкиваются с вопросом “что сегодня приготовить?”.
                                Если
                                подходить к этому вопросу хаотично, то вы будете тратить много времени на выбор блюд и,
                                скорее
                                всего, питаться однообразно. <br/><br/>
                                Мобильное приложение CookHaus как раз создано для того, чтобы решить эту проблему. В
                                приложении
                                у вас будет возможность планировать своё меню на несколько дней или неделю вперед,
                                выбирая из
                                тех рецептов, что подобраны под ваши предпочтения. Затем, по созданному меню можно
                                сформировать
                                список покупок: так вы не купите лишнее и не забудете купить нужное.
                            </Typography>
                        </Grid2>
                    </Grid2>


                    <Button variant="contained"
                            color="secondary"
                            sx={{
                                color: 'white',
                                mt: 3,
                                textTransform: 'none',
                                borderRadius: 2,
                                display: {
                                    xs: 'block',
                                    sm: 'none'
                                }
                            }}
                            size="large"
                            onClick={handleNext}
                            fullWidth={true}>
                        Хочу попробовать
                    </Button>
                </Stack>

                <div className="pw-secondaryBlock">
                    <Container maxWidth="sm" sx={{p: 0, mt: 2}}>
                        <Stack direction="column"
                               alignItems="center"
                               className="secondary-background-color"
                               sx={{p: 2, borderRadius: 3}}>
                            <Stack direction="row" alignItems="center">
                                <Box component="img"
                                     src="/assets/quiz_first/gift.png"
                                     width="80px"
                                     height="80px"/>

                                <Typography variant="body2" fontSize="24px" textAlign="center">
                                    Получите бонус за прохождение опроса и регистрацию: статью с секретами быстрого
                                    планирования
                                    еженедельного меню
                                </Typography>
                            </Stack>

                            <Typography variant="body1" fontSize="20px" fontWeight="bold" textAlign="center" mt={2}>
                                Опрос в среднем занимает не больше 3-х минут.
                            </Typography>

                            <Button variant="contained"
                                    color="primary"
                                    sx={{
                                        color: 'white',
                                        mt: 3,
                                        textTransform: 'none',
                                        borderRadius: 2,
                                        fontSize: {
                                            xs: '16px',
                                            sm: '20px'
                                        }
                                    }}
                                    size="large"
                                    onClick={handleNext}
                                    fullWidth={true}>
                                Пройти опрос
                            </Button>
                        </Stack>
                    </Container>
                </div>

                <Box mt={3}/>
            </AppContainer>
        </Transitions>
    );
}

export default IntroPage;