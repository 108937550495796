import {useTypedSelector} from "../store/store";
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {ROUTES, useAppNavigate, useOfferCodeFromQuery} from "../helpers/routing";
import AppContainer from "../components/appContainer";
import {Alert, Box, Button, Checkbox, Divider, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useScriptsFromHtml} from "../helpers/hooks";
import {createPayment, getPaymentStatus} from "../api/api";
import {CreatePaymentResult, PaymentStatus} from "../api/types";
import {sendStartPayment, sendSuccessfulPayment} from "../helpers/analytics";
import {CancelSharp, CheckCircleSharp} from "@mui/icons-material";
import {addDaysToDate, formatDate} from "../helpers/dates";
import {AndroidInstruction} from "./endPayment";
import {Transitions} from "../components/transitions";

interface PayButtonProps {
    onPaymentInitialized: (pr: CreatePaymentResult) => void;
    isPaymentLoading: boolean;
}

const PayButton = ({onPaymentInitialized, isPaymentLoading}: PayButtonProps) => {
    const [isCreatePaymentLoading, setIsCreatePaymentLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [confirmConsent, setConfirmConsent] = useState(false);
    const isLoading = useMemo(() => isCreatePaymentLoading || isPaymentLoading, [isPaymentLoading, isCreatePaymentLoading]);
    const offerCode = useOfferCodeFromQuery();

    const handleConfirmConsent = useCallback(() => {
        setConfirmConsent(!confirmConsent);
        setError(undefined);
    }, [confirmConsent]);

    const handleClick = useCallback(async () => {
        if (!confirmConsent) {
            setError("Нужно согласиться с условиями использования ниже, чтобы продолжить.");
            return;
        }

        setIsCreatePaymentLoading(true);
        setError(undefined);

        const paymentResult = await createPayment(offerCode);
        if (!paymentResult || !paymentResult.result) {
            setError("Произошла неизвестная ошибка инициализации платежа. Пожалуйста, попробуйте еще раз.")
            return;
        }

        setIsCreatePaymentLoading(false);
        onPaymentInitialized(paymentResult.result);
    }, [offerCode, onPaymentInitialized, confirmConsent]);


    return (
        <Stack direction="column" width="100%" alignItems="center" justifyContent="center">
            {error &&
                <Alert severity="error" sx={{mb: 2}}>
                    {error}
                </Alert>
            }

            <Stack direction="row" alignItems="center">
                <Checkbox
                    color="secondary"
                    centerRipple
                    checked={confirmConsent}
                    onChange={handleConfirmConsent}/>

                <Box component="div"
                     onClick={handleConfirmConsent}
                     sx={{cursor: 'pointer'}}>
                    <Typography variant="body1" fontSize="14px" textAlign="left">
                        Я согласен/согласна с условиями <a href="https://cookhaus.guru/terms_of_use" rel="noreferrer"
                                                           target="_blank">публичной
                        оферты</a>, даю согласие на регулярные списания, на обработку персональных данных.
                    </Typography>
                </Box>
            </Stack>

            <Box mt={2}/>
            {isLoading && <div className="dots-flashing"/>}

            {!isLoading &&
                <Button variant="contained"
                        color="primary"
                        sx={{
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: 4
                        }}
                        onClick={handleClick}
                        size="large"
                        fullWidth={true}>
                    Далее
                </Button>
            }
        </Stack>
    );
};

interface PaymentStatusProps {
    status: PaymentStatus;
}

const SuccessPaymentStatus = () => {
    return (
        <Stack direction="column" alignItems="center">
            <CheckCircleSharp color="success" sx={{fontSize: '96px'}}/>
            <Typography variant="body2" fontSize="26px" fontWeight="bold">
                Спасибо за покупку!
            </Typography>

            <Typography variant="body2" mt={1} fontSize="18px">
                Ваш платеж успешно принят.
            </Typography>

            <Typography variant="h2" mt={3} fontSize="24px" fontWeight="bold">
                Что дальше?
            </Typography>

            <Box mt={3}/>
            <AndroidInstruction/>
        </Stack>
    );
};

const FailedPaymentStatus = () => {
    return (
        <Stack direction="column" alignItems="center">
            <CancelSharp color="error" sx={{fontSize: '96px'}}/>
            <Typography variant="body2" fontSize="26px" fontWeight="bold">
                Произошла ошибка :(
            </Typography>

            <Typography variant="body2" mt={3} fontSize="18px">
                К сожалению, ваш платеж отклонен. Если в результате ошибки средства были списаны, они вскоре вернуться
                обратно.
                Мы не знаем, почему это произошло, однако вы можете попробовать оплатить еще раз.
            </Typography>

            <Box mt={3}/>
            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        textTransform: 'none',
                        borderRadius: 4
                    }}
                    onClick={() => window.location.reload()}
                    size="large"
                    fullWidth={true}>
                Попробовать еще раз
            </Button>
        </Stack>
    );
};

const PaymentStatusBlock = ({status}: PaymentStatusProps) => {
    if (status === PaymentStatus.Paid) {
        return (<SuccessPaymentStatus/>);
    } else if (status === PaymentStatus.Failed) {
        return (<FailedPaymentStatus/>);
    } else {
        return (<></>);
    }
};

const isFinalPaymentStatus = (ps: PaymentStatus): boolean => ps === PaymentStatus.Paid || ps === PaymentStatus.Failed;

const PayPage = () => {
    const accountState = useTypedSelector(s => s.account);
    const navigate = useAppNavigate();
    const [paymentId, setPaymentId] = useState<number | undefined>(undefined);
    const [jsWidgetCode, setJsWidgetCode] = useState<string | undefined>(undefined);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.Created);
    const isPaymentLoading = useMemo(() => paymentId !== undefined, [paymentId]);

    useLayoutEffect(() => {
        if (!accountState.authToken) {
            navigate(ROUTES.REGISTER_ACCOUNT);
        }
    }, [accountState, navigate]);

    useScriptsFromHtml(jsWidgetCode);
    useEffect(() => {
        window.addEventListener('message', e => {
            if (!e.origin.includes('auth.robokassa')) {
                return;
            }

            if (e.data.action === 'closeRobokassaFrame') {
                setTimeout(() => {
                    setPaymentId(undefined);
                }, 7000);
            }
        });
    }, []);

    useEffect(() => {
        if (!paymentId) {
            return;
        }

        const interval = setInterval(async () => {
            const payment = await getPaymentStatus(paymentId.toString());
            console.log(payment?.status === PaymentStatus.Initialized)
            if (payment && (payment.status === PaymentStatus.Paid || payment.status === PaymentStatus.Failed)) {
                setPaymentStatus(payment.status);
                clearInterval(interval);
                sendSuccessfulPayment(payment.amount);
                return;
            }
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [paymentId]);

    const handleInitializedPayment = useCallback((pr: CreatePaymentResult) => {
        if (pr.jsWidget === '') {
            window.location.href = pr.paymentUrl;
            return;
        }

        setJsWidgetCode(pr.jsWidget);
        setPaymentId(pr.paymentId);
        sendStartPayment();
    }, []);

    const after7Days = addDaysToDate(new Date(), 8);
    return (
        <AppContainer>
            <Transitions key="pay">
                {!isFinalPaymentStatus(paymentStatus) &&
                    <>
                        <Paper elevation={0} className="secondary-background-color"
                               sx={{p: 2, border: '#e3e3e3 1px solid;', borderRadius: 4}}>
                            <Stack direction="column">
                                <Box
                                    id="anim"
                                    component="img"
                                    src="/assets/paywall/subscription_anim.gif"
                                    width="150px"
                                    height="150px"
                                    sx={{
                                        alignSelf: "center"
                                    }}
                                />

                                <Typography variant="body1" fontSize="22px">
                                    Пробный доступ на 7 дней
                                </Typography>

                                <Typography variant="body1" fontSize="16px" mt={1} textAlign="left">
                                    После оплаты вы получите полный доступ ко всем Premium-возможностям приложения на
                                    неделю,
                                    включая:<br/><br/>
                                    ✅ создание меню с подобранными <b>под вас</b> рецептами<br/>
                                    ✅ <b>автоматический</b> список покупок<br/>
                                    ✅ возможность создавать <b>коллекции</b><br/>
                                    ✅ <b>более 1000</b> простых и вкусных рецептов!<br/><br/>
                                    Инструкции по скачиванию приложения появятся на этой странице сразу после оплаты, а
                                    также
                                    будут отправлены на вашу почту.
                                </Typography>
                                <Box mt={2}/>
                                <Divider sx={{margin: '0 -16px'}}/>
                                <Box mt={2}/>

                                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" fontSize="18px">
                                        К оплате сейчас
                                    </Typography>

                                    <Typography variant="body1" fontSize="18px" fontWeight="bold">
                                        1 ₽
                                    </Typography>
                                </Stack>

                                <Box mt={2}/>
                                <PayButton onPaymentInitialized={handleInitializedPayment}
                                           isPaymentLoading={isPaymentLoading}/>
                            </Stack>
                        </Paper>

                        <Box mt={1}/>
                        <Paper elevation={0} sx={{p: 2, border: '#e3e3e3 1px solid;', borderRadius: 4}}>
                            <Typography variant="body1" fontSize="15px" mt={1} textAlign="left">
                                Следующее списание 299 ₽ произойдет автоматически, если вы не отмените подписку
                                до {formatDate(after7Days)} в личном кабинете, либо мобильном приложении CookHaus.
                                Если вы забудете отменить автоматическое списание, вы сможете вернуть средства в течение
                                48
                                часов после списания.
                            </Typography>
                        </Paper>
                    </>
                }

                {isFinalPaymentStatus(paymentStatus) && <PaymentStatusBlock status={paymentStatus}/>}
                <Box mt={3}/>
            </Transitions>
        </AppContainer>
    );
};

export default PayPage;