import {QuestionData} from "../store/quizSlice";

export const [
    QuestionSex,
    QuestionGoal,
    QuestionAge,
    QuestionHeight,
    QuestionWeight,
    QuestionActivity,
    QuestionMealTypes,
    QuestionPersons,
    QuestionEstimatedMinutes,
    QuestionExperience,
    QuestionExcludedCategories,
    QuestionIngredientPreferences,
    QuestionMealExperiments,
    QuestionIngredientExperiments,
    QuestionDaysWithRepetition,
]: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15
];

export const [
    QuestionTypeSelect,
    QuestionTypeChips,
    QuestionTypeInput
]: string[] = [
    'select',
    'chips',
    'input'
];

const questionsData: Record<number, QuestionData> = {
    [QuestionGoal]: {
        type: QuestionTypeSelect,
        text: 'Укажите свою основную цель в питании',
        options: [
            {
                text: 'Поддерживать вес',
                name: 'maintain'
            },
            {
                text: 'Сбросить вес',
                name: 'drop'
            },
            {
                text: 'Набрать вес',
                name: 'gain'
            }
        ],
        isMultiSelect: false
    },
    [QuestionAge]: {
        type: QuestionTypeInput,
        text: 'Ваш возраст',
        suffix: 'лет',
        validation: {
            from: 16,
            to: 100
        }
    },
    [QuestionSex]: {
        type: QuestionTypeSelect,
        text: 'Ваш пол',
        options: [
            {
                text: 'Женский',
                icon: 'female',
                name: 'female'
            },
            {
                text: 'Мужской',
                icon: 'male',
                name: 'male'
            }
        ],
        isMultiSelect: false
    },
    [QuestionHeight]: {
        type: QuestionTypeInput,
        text: 'Ваш рост',
        suffix: 'см',
        validation: {
            from: 120,
            to: 250
        }
    },
    [QuestionWeight]: {
        type: QuestionTypeInput,
        text: 'Ваш вес',
        suffix: 'кг',
        validation: {
            from: 35,
            to: 300
        }
    },
    [QuestionActivity]: {
        type: QuestionTypeSelect,
        text: 'Ваш уровень дневной активности',
        options: [
            {
                text: 'Очень низкая',
                subtext: 'Редко выхожу из дома, почти весь день сижу',
                name: 'verylow'
            },
            {
                text: 'Низкая',
                subtext: 'Хожу в магазин или недолго прогуливаюсь',
                name: 'low'
            },
            {
                text: 'Средняя',
                subtext: 'Ежедневно гуляю не меньше часа',
                name: 'mid'
            },
            {
                text: 'Высокая',
                subtext: 'Занимаюсь активными видами 2-3 раза в неделю',
                name: 'high'
            },
            {
                text: 'Очень высокая',
                subtext: 'Регулярно занимаюсь минимум 5 раз в неделю',
                name: 'veryhigh'
            }
        ],
        isMultiSelect: false
    },
    [QuestionMealTypes]: {
        type: QuestionTypeSelect,
        text: 'Укажите приемы пищи, которые хотите планировать',
        options: [
            {
                text: 'Завтрак',
                name: 'Breakfast'
            },
            {
                text: 'Обед',
                name: 'Lunch'
            },
            {
                text: 'Ужин',
                name: 'Dinner'
            }
        ],
        isMultiSelect: true
    },
    [QuestionPersons]: {
        type: QuestionTypeSelect,
        text: 'Укажите количество человек для блюд в меню',
        options: [
            {
                text: 'Один',
                name: 'one'
            },
            {
                text: 'Двое',
                name: 'two'
            },
            {
                text: 'Трое',
                name: 'three'
            },
            {
                text: 'Четверо',
                name: 'four'
            },
            {
                text: 'Пятеро',
                name: 'five'
            },
            {
                text: 'Шестеро',
                name: 'six'
            }
        ],
        isMultiSelect: false
    },
    [QuestionEstimatedMinutes]: {
        type: QuestionTypeSelect,
        text: 'Выберите, какое время вы готовы тратить на готовку одного блюда',
        options: [
            {
                text: 'Не более 30 мин.',
                name: 'half_hour'
            },
            {
                text: 'Не более часа',
                name: 'hour'
            },
            {
                text: 'Не больше полутора часов',
                name: 'hour_and_half'
            },
            {
                text: 'Не имеет значения',
                name: 'not_matter'
            }
        ],
        isMultiSelect: false
    },
    [QuestionExperience]: {
        type: QuestionTypeSelect,
        text: 'Как вы оцениваете свои кулинарные навыки?',
        options: [
            {
                text: 'Новичок',
                subtext: 'В меню попадут блюда только низкой сложности.',
                name: 'newbie'
            },
            {
                text: 'С опытом',
                subtext: 'В меню будут блюда низкой и средней сложности.',
                name: 'experienced'
            },
            {
                text: 'Продвинутый',
                subtext: 'В меню могут попадаться блюда высокой сложности.',
                name: 'advanced'
            }
        ],
        isMultiSelect: false
    },
    [QuestionExcludedCategories]: {
        type: QuestionTypeChips,
        text: 'Выберите категории продуктов, которые хотели бы исключить',
        chips: [
            {
                icon: '/assets/chips/peas.png',
                text: 'Бобовые',
                name: 'Legumes'
            },
            {
                icon: '/assets/chips/dairy.png',
                text: 'Молочные продукты',
                name: 'Dairy'
            },
            {
                icon: '/assets/chips/eggs.png',
                text: 'Яйца',
                name: 'Eggs'
            },
            {
                icon: '/assets/chips/meat.png',
                text: 'Мясные продукты',
                name: 'Meat'
            },
            {
                icon: '/assets/chips/honey.png',
                text: 'Мёд',
                name: 'Honey'
            },
            {
                icon: '/assets/chips/hazelnut.png',
                text: 'Орехи и семена',
                name: 'WalnutAndSeeds'
            },
            {
                icon: '/assets/chips/mushrooms.png',
                text: 'Грибы',
                name: 'Mushrooms'
            },
            {
                icon: '/assets/chips/seafood.png',
                text: 'Рыба и морепродукты',
                name: 'Fish'
            }
        ]
    },
    [QuestionIngredientPreferences]: {
        type: QuestionTypeChips,
        text: 'Выберите продукты, которые предпочитаете больше других',
        chips: [
            {
                icon: '/assets/chips/chicken.png',
                text: 'Курица',
                name: 'meat_Chicken'
            },
            {
                icon: '/assets/chips/chop.png',
                text: 'Свинина',
                name: 'meat_Pork'
            },
            {
                icon: '/assets/chips/beef.png',
                text: 'Говядина',
                name: 'meat_Beef'
            },
            {
                icon: '/assets/chips/turkey.png',
                text: 'Индейка',
                name: 'meat_Turkey'
            },
            {
                icon: '/assets/chips/lamb.png',
                text: 'Баранина',
                name: 'meat_Mutton'
            },
            {
                icon: '/assets/chips/peking-duck.png',
                text: 'Утка',
                name: 'meat_Duck'
            },
            {
                icon: '/assets/chips/rabbit.png',
                text: 'Кролик',
                name: 'meat_Rabbit'
            },
            {
                icon: '/assets/chips/mackerel.png',
                text: 'Скумбрия',
                name: 'fish_Mackerel'
            },
            {
                icon: '/assets/chips/herring.png',
                text: 'Сельдь',
                name: 'fish_Herring'
            },
            {
                icon: '/assets/chips/sea.png',
                text: 'Горбуша',
                name: 'fish_PinkSalmon'
            },
            {
                icon: '/assets/chips/pollock.png',
                text: 'Минтай',
                name: 'fish_Pollock'
            },
            {
                icon: '/assets/chips/trout.png',
                text: 'Форель',
                name: 'fish_Trout'
            },
            {
                icon: '/assets/chips/salmon.png',
                text: 'Лосось',
                name: 'fish_Salmon'
            },
            {
                icon: '/assets/chips/capelin.png',
                text: 'Мойва',
                name: 'fish_Capelin'
            },
            {
                icon: '/assets/chips/shrimp.png',
                text: 'Креветки',
                name: 'fish_Shrimps'
            },
            {
                icon: '/assets/chips/potato.png',
                text: 'Картофель',
                name: 'cereal_Potato'
            },
            {
                icon: '/assets/chips/rice.png',
                text: 'Рис',
                name: 'cereal_Rice'
            },
            {
                icon: '/assets/chips/food.png',
                text: 'Гречка',
                name: 'cereal_Buckwheat'
            },
            {
                icon: '/assets/chips/pasta.png',
                text: 'Макароны',
                name: 'cereal_Pasta'
            },
            {
                icon: '/assets/chips/lentil.png',
                text: 'Чечевица',
                name: 'cereal_Lentils'
            },
            {
                icon: '/assets/chips/8079188.png',
                text: 'Перловка',
                name: 'cereal_PearlBarley'
            },
            {
                icon: '/assets/chips/bay.png',
                text: 'Лавровый лист',
                name: 'spicy_BayLeaf'
            },
            {
                icon: '/assets/chips/dill.png',
                text: 'Укроп',
                name: 'spicy_Dill'
            },
            {
                icon: '/assets/chips/jar.png',
                text: 'Хмели-сунели',
                name: 'spicy_KhmeliSuneli'
            },
            {
                icon: '/assets/chips/turmeric.png',
                text: 'Куркума',
                name: 'spicy_Turmeric'
            },
            {
                icon: '/assets/chips/paprika.png',
                text: 'Паприка',
                name: 'spicy_Paprika'
            },
            {
                icon: '/assets/chips/basil.png',
                text: 'Базилик',
                name: 'spicy_Basil'
            },
            {
                icon: '/assets/chips/cooking.png',
                text: 'Карри',
                name: 'spicy_Curry'
            },
            {
                icon: '/assets/chips/ginger.png',
                text: 'Имбирь',
                name: 'spicy_Ginger'
            }
        ]
    },
    [QuestionMealExperiments]: {
        type: QuestionTypeSelect,
        text: 'Как часто вы бы хотели готовить новые блюда?',
        options: [
            {
                text: 'Часто',
                name: 'often'
            },
            {
                text: 'Иногда',
                name: 'sometimes'
            },
            {
                text: 'Редко',
                name: 'rarely'
            }
        ],
        isMultiSelect: false
    },
    [QuestionIngredientExperiments]: {
        type: QuestionTypeSelect,
        text: 'Предпочитаете ли разнообразие в наборе ингредиентов?',
        options: [
            {
                text: 'Да, предпочитаю',
                name: 'often'
            },
            {
                text: 'Лучше постоянный набор',
                name: 'sometimes'
            },
            {
                text: 'Минимум новых ингредиентов',
                name: 'rarely'
            }
        ],
        isMultiSelect: false
    },
    [QuestionDaysWithRepetition]: {
        type: QuestionTypeSelect,
        text: 'Сколько дней подряд блюдо может повторяться?',
        options: [
            {
                text: 'Один',
                subtext: 'Каждый день в меню разные блюда.',
                name: 'one'
            },
            {
                text: 'Два',
                subtext: 'Каждые два дня в меню разные блюда.',
                name: 'two'
            },
            {
                text: 'Три',
                subtext: 'Каждые три дня в меню разные блюда.',
                name: 'three'
            },
            {
                text: 'Четыре',
                subtext: 'Каждые четыре дня в меню разные блюда.',
                name: 'four'
            },
        ]
    }
};

export interface Feature {
    order: number;
    imageName: string;
    text: string;
    isActive: boolean;
}

const featuresData: Feature[] = [
    {
        order: 1,
        imageName: 'light-bulb.png',
        text: 'Идеи рецептов для завтраков, обедов и ужинов',
        isActive: false
    },
    {
        order: 2,
        imageName: 'july.png',
        text: 'Планирование меню',
        isActive: false
    },
    {
        order: 3,
        imageName: 'food.png',
        text: 'Здоровое питание',
        isActive: false
    },
    {
        order: 4,
        imageName: 'shopping-list.png',
        text: 'Автоматические списки покупок',
        isActive: false
    }
];

const OverallQuestionsCount: number = Object.keys(questionsData).length;

export {questionsData, featuresData, OverallQuestionsCount};