import AppContainer from "../components/appContainer";
import Stack from "@mui/material/Stack";
import React, {FC, Ref, RefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Container,
    Grow,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {bmiMax, bmiMin, calcCaloriesAndBmi, CalcCaloriesAndBmiParams, getBmiPhrase} from "../helpers/bmiCalc";
import Grid2 from "@mui/material/Unstable_Grid2";
import {CreateCircle} from "../helpers/kcalCircle";
import {useTypedSelector} from "../store/store";
import {
    QuestionActivity,
    QuestionAge,
    QuestionGoal,
    QuestionHeight,
    questionsData,
    QuestionSex,
    QuestionWeight
} from "../data/quiz";
import {useTimer} from "react-timer-hook";
import {createPayment} from "../api/api";
import {sendRedirectFromPaywallToRegistration, sendScrollToPay, sendStartPayment} from "../helpers/analytics";
import {ROUTES, useAppNavigate, useOfferCodeFromQuery} from "../helpers/routing";
import {Transitions} from "../components/transitions";

interface BmiLineProps {
    bmi: number;
}

const BmiLine: FC<BmiLineProps> = ({bmi}) => {
    const cursorPosPercent: number = (bmi - bmiMin) / (bmiMax - bmiMin) * 100;

    return (
        <Stack direction="column" width="100%">
            <Box id="bmiLine"
                 component="div"
                 mt={3}
                 sx={{
                     position: 'relative',
                     height: '8px',
                     borderRadius: 2,
                     background: 'linear-gradient(90deg, #4098ff, #32dc80 29.17%, #ffde00 60.94%, #ff5252)',
                     width: '100%'
                 }}>

                <Typography variant="h6"
                            component="div"
                            sx={{
                                position: 'absolute',
                                transform: 'translate(-50%, calc(-100% - 8px));',
                                left: `${cursorPosPercent}%`
                            }}>
                    {bmi}
                </Typography>

                <Box id="bmiLine_cursor"
                     component="div"
                     sx={{
                         position: 'absolute',
                         top: 0,
                         bottom: 0,
                         width: '4px',
                         borderRadius: '2px',
                         outline: '3px solid #fff',
                         background: '#000',
                         left: `${cursorPosPercent}%`
                     }}/>
            </Box>

            <Grid2 container columns={3} mt={1}>
                <Grid2 xs>
                    Дефицит
                </Grid2>
                <Grid2 xs>
                    Норма
                </Grid2>
                <Grid2 xs>
                    Избыток
                </Grid2>
            </Grid2>
        </Stack>
    );
};

interface BmiAlertProps {
    bmi: number;
}

const BmiAlert: FC<BmiAlertProps> = ({bmi}) => {
    const {
        title,
        description,
        type
    } = getBmiPhrase(bmi);

    return (
        <Box mt={2}>
            <Alert severity={type}
                   variant="outlined"
                   sx={{
                       textAlign: 'left'
                   }}>
                <AlertTitle fontSize="17px">{title}</AlertTitle>
                {description}
            </Alert>
        </Box>
    );
};

interface NutrientStatProps {
    color: string;
    name: string;
    quantity: string;
}

const NutrientStat = ({color, name, quantity}: NutrientStatProps) => (
    <Stack direction="row" spacing={1} alignItems="center">
        <Box component="div"
             sx={{
                 borderRadius: "100%",
                 height: "15px",
                 width: "15px",
                 background: color
             }}/>
        <Stack direction="column">
            <Typography variant="body2" fontWeight="bold" fontSize="13pt">{quantity}</Typography>
            <Typography variant="body2" fontSize="13pt" color="#97A2B0">{name}</Typography>
        </Stack>
    </Stack>
);

interface DailyKcalValueProps {
    kcal: number;
    proteins: number;
    fats: number;
    carbs: number;
    goal: string[];
}

const DailyKcalValue = ({kcal, proteins, fats, carbs, goal}: DailyKcalValueProps) => {
    useEffect(() => {
        CreateCircle('kcalCircle')
    }, []);

    const goalText = (questionsData[QuestionGoal].options || []).find(o => (goal || []).some(g => g === o.name))?.text?.toLowerCase();
    return (
        <Stack direction="column" width="100%">
            <Typography variant="h5" fontSize="28px">
                Ваша суточная норма калорий
            </Typography>

            <Stack direction="row" mt={2} alignItems="center" spacing={2}>
                <Box id="kcalCircle"
                     component="svg"
                     viewBox="0 0 100 100"
                     width="100px"
                     height="100px"
                     mt={2}
                />

                <Typography variant="h5" fontWeight="bold">
                    {(+Math.round(kcal)).toLocaleString()} кКал*
                </Typography>
            </Stack>

            <Typography variant="body1" mt={2} textAlign="left" fontSize="14pt">
                Из которых
            </Typography>

            <Stack direction="row" spacing={3} mt={1}>
                <NutrientStat color='#F8E71C' name='Белки'
                              quantity={`${(Math.round(proteins * 100) / 100).toLocaleString()} гр`}/>
                <NutrientStat color='#BD10E0' name='Жиры'
                              quantity={`${(Math.round(fats * 100) / 100).toLocaleString()} гр`}/>
                <NutrientStat color='#FF0080' name='Углеводы'
                              quantity={`${(Math.round(carbs * 100) / 100).toLocaleString()} гр`}/>
            </Stack>

            <Alert severity="info"
                   variant="outlined"
                   sx={{
                       mt: 2,
                       textAlign: 'left'
                   }}>
                <AlertTitle fontSize="17px">Почему важно соблюдать суточную норму калорий?</AlertTitle>

                <Typography variant="body1" fontSize="15px">
                    Соблюдать суточную норму калорий и баланс белков, жиров и углеводов важно, чтобы чувствовать себя
                    хорошо, быть энергичным и двигаться к вашей цели — <strong>{goalText}</strong>.
                    <br/><br/>
                    Но, честно сказать, считать калории — задача не из лёгких. Это постоянно вылетает из головы. Тут на
                    помощь приходит наше <strong>приложение</strong>, которое <strong>считает калории само</strong> и
                    экономит кучу времени!
                </Typography>
            </Alert>
        </Stack>
    );
}

export const UserBmiBlock = () => {
    const state = useTypedSelector(state => state.quiz);
    const calcParams: CalcCaloriesAndBmiParams = {
        weight: Number(state.answers[QuestionWeight]),
        height: Number(state.answers[QuestionHeight]),
        age: Number(state.answers[QuestionAge]),
        sex: state.answers[QuestionSex] as any,
        activityLevel: state.answers[QuestionActivity] as any,
        goal: state.answers[QuestionGoal] as any
    };

    const {
        bmi,
        kcal,
        proteins,
        fats,
        carbs
    } = useMemo(() => calcCaloriesAndBmi(calcParams), []);

    const roundedBmi: number = Math.round(bmi * 100) / 100;
    return (
        <Grid2 container
               columns={{
                   xs: 1,
                   sm: 2
               }}
               columnSpacing={2}
               alignItems="top"
               className="pw-secondaryBlock"
               rowSpacing={1}
               p={{
                   xs: 1,
                   sm: 2
               }}>
            <Grid2 xs={1} order={{xs: 0, sm: 1}}>
                <Stack>
                    <Typography variant="h1" fontSize="28px">
                        Ваш индекс <br/>массы тела (ИМТ)
                    </Typography>
                    <Box mt={2}/>
                    <BmiLine bmi={roundedBmi}/>
                    <BmiAlert bmi={roundedBmi}/>
                </Stack>
            </Grid2>

            <Grid2 xs={1}>
                <DailyKcalValue kcal={kcal} proteins={proteins} fats={fats} carbs={carbs}
                                goal={state.answers[QuestionGoal] as any}/>
            </Grid2>
        </Grid2>
    );
};

interface BuyBlockProps {
    minutes: number;
    seconds: number;
}

const BuyBlock = ({minutes, seconds}: BuyBlockProps) => {
    const offerAvailable = minutes > 0 || seconds > 0;
    const navigate = useAppNavigate();

    const handleNext = useCallback(async () => {
        navigate(ROUTES.REGISTER_ACCOUNT);
        sendRedirectFromPaywallToRegistration();
    }, [navigate]);

    return (
        <Stack
            direction="column"
            p={2}
            alignItems="center">
            <Box sx={{position: 'relative', display: 'inline-block'}} component="div">
                <Box
                    id="image"
                    component="img"
                    src="/assets/logo_quiz.png"
                    sx={{display: 'block'}}
                />

                <Box
                    id="particles"
                    component="img"
                    src="/assets/animation_particles.gif"
                    sx={{position: 'absolute', top: '-20%', left: 0, display: 'block'}}
                />
            </Box>

            <Typography variant="h1" fontSize="32px" lineHeight="40px" mt={2}>
                Получите полный доступ
                на <strong className="brand-secondary-color">
                7 дней</strong> за <strong className="brand-secondary-color">1 ₽</strong>
            </Typography>

            {offerAvailable &&
                <>
                    <Typography variant="body1" fontSize="20px" mt={2}>
                        Предложение действует еще
                    </Typography>

                    <Typography variant="body1" fontSize="32px" fontWeight="bold">
                        {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                    </Typography>
                </>
            }

            <Typography variant="body1" fontSize="18px">
                Пробная неделя — отличная возможность попробовать все функции приложения не переживая за то, что
                потратите деньги впустую. Далее — 299 ₽ в месяц, отменить подписку можно в любой момент.
            </Typography>

            <Button variant="contained"
                    color="primary"
                    sx={{
                        color: 'white',
                        mt: 2,
                        textTransform: 'none',
                        borderRadius: 4,
                        fontSize: '20px',
                        p: 1
                    }}
                    size="large"
                    onClick={handleNext}
                    fullWidth={true}>
                Продолжить
            </Button>
        </Stack>
    );
};

interface ScreenAppBlockParams {
    colored: boolean;
    imgName: string;
    header: string;
    desc: string;
}

const ScreenAppBlock = ({colored, imgName, header, desc}: ScreenAppBlockParams) => {
    return (
        <Grid2 container
               columns={{
                   xs: 1,
                   sm: 2
               }}
               alignItems="center"
               rowSpacing={{
                   xs: 1,
                   sm: 0
               }}
               p={{
                   xs: 1,
                   sm: 3
               }}
               className={colored ? 'pw-secondaryBlock' : ''}>
            <Grid2 xs={1} order={{xs: 0, sm: colored ? 0 : 1}}>
                <Box component="img"
                     src={`/assets/paywall/${imgName}`}
                     width="250px"
                />
            </Grid2>

            <Grid2 xs={1}>
                <Typography variant="body1" fontWeight="bold" sx={{
                    fontSize: {
                        xs: '22px',
                        sm: '26px'
                    }
                }}>
                    {header}
                </Typography>

                <Typography variant="body1" mt={1.5} sx={{
                    fontSize: {
                        xs: '18px',
                        sm: '20px'
                    }
                }}>
                    {desc}
                </Typography>
            </Grid2>
        </Grid2>
    );
};

interface FloatingNextButtonProps {
    minutes: number;
    seconds: number;
    isVisible: boolean;
    refToScroll: RefObject<HTMLDivElement>;
}

const FloatingNextButton = ({minutes, seconds, isVisible, refToScroll}: FloatingNextButtonProps) => {
    const offerAvailable = minutes > 0 || seconds > 0;
    const theme = useTheme();
    const maxWidth = useMediaQuery(theme.breakpoints.down("sm"))
        ? "xs"
        : "md";

    return (
        <Grow in={isVisible}>
            <Paper elevation={0}
                   sx={{
                       position: 'fixed',
                       bottom: 0,
                       zIndex: 999,
                       left: 0,
                       width: '100%',
                       backdropFilter: 'saturate(180%) blur(20px);',
                       backgroundColor: 'rgba(255, 255, 255, 0.65)',
                       borderTop: '1px solid #E5E5E5'
                   }}>
                <Container maxWidth={maxWidth} sx={{p: 0, width: '100%'}}>
                    <Stack direction="row" p={1} justifyContent="space-between">
                        {offerAvailable &&
                            <Typography variant="body1" fontSize="16px">
                                Скидка действует еще <br/><strong
                                className="brand-secondary-color">{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</strong>
                            </Typography>
                        }

                        <Button variant="contained"
                                color="primary"
                                sx={{
                                    color: 'white',
                                    textTransform: 'none',
                                    borderRadius: 4,
                                }}
                                onClick={() => refToScroll?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                })}
                                fullWidth={!offerAvailable}
                                size="medium">
                            Продолжить
                        </Button>
                    </Stack>
                </Container>
            </Paper>
        </Grow>
    );
};

const PaywallPage = () => {
    const timerExpireDate = new Date();
    timerExpireDate.setMinutes(timerExpireDate.getMinutes() + 15);
    const {minutes, seconds} = useTimer({expiryTimestamp: timerExpireDate, autoStart: true});
    const buyBlockRef = useRef<HTMLDivElement>(null);
    const [isFixedButtonVisible, setIsFixedButtonVisible] = useState<boolean>(true);

    useEffect(() => {
        const targetElement = buyBlockRef.current;
        if (!targetElement) return;

        const observer = new IntersectionObserver(([entry]) => {
            setIsFixedButtonVisible(!entry.isIntersecting);
            if (entry.isIntersecting) {
                sendScrollToPay();
            }
        }, {
            root: null,
            threshold: 0.1
        })

        observer.observe(targetElement);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <AppContainer maxWidth="md">
            <Transitions key="paywall">
                <Stack direction="column">
                    <Typography variant="h1"
                                lineHeight="110%"
                                sx={{
                                    fontSize: {
                                        xs: '32px',
                                        sm: '36px'
                                    }
                                }}>
                        Получите персонализированное меню
                    </Typography>

                    <Typography variant="body1"
                                lineHeight="110%"
                                mt={2}
                                sx={{
                                    fontSize: {
                                        xs: '20px',
                                        sm: '24px'
                                    }
                                }}>
                        Мы подобрали больше 100 рецептов, которые с наибольшей вероятностью подойдут вам.
                    </Typography>

                    <Box mt={3}/>
                    <ScreenAppBlock colored={true} imgName="screen1.png" header="Планируйте меню за несколько минут"
                                    desc="Благодаря рекомендациям, подобранным нами на основе ваших ответов, вы можете тратить на составление своего меню намного меньше времени"/>
                    <ScreenAppBlock colored={false} imgName="screen2.png"
                                    header="Получайте готовый список покупок к меню"
                                    desc="Больше не нужно тратить время на составление списка покупок. Приложение само посчитает нужное количество каждого ингредиента на основе вашего меню"/>
                    <ScreenAppBlock colored={true} imgName="screen3.png" header="Возьмите свое питание под контроль"
                                    desc="CookHaus будет подбирать такие рецепты, которые соответствуют вашему профилю питания и позволят питаться более сбалансированно"/>
                    <ScreenAppBlock colored={false} imgName="screen4.png" header="Не теряйте свои любимые рецепты"
                                    desc="Вы сможете создавать коллекции из своих любимых рецептов, чтобы периодически к ним возвращаться и порадовать ими себя или своих близких снова"/>

                    <Box mt={2}/>
                    <UserBmiBlock/>

                    <Stack direction="column" mt={2} alignItems="center">
                        <Typography variant="h1"
                                    sx={{
                                        fontSize: {
                                            xs: '28px',
                                            sm: '32px'
                                        },
                                        lineHeight: {
                                            xs: '37px',
                                            sm: '42px'
                                        }
                                    }}>
                            Упорядочьте своё питание <br/>
                            <Box component="span"
                                 sx={{
                                     backgroundColor: '#3DA0A7',
                                     color: 'white',
                                     borderRadius: 6,
                                     p: 0.5
                                 }}>
                                уже сейчас
                            </Box>
                        </Typography>

                        <Typography variant="body1"
                                    mt={2}
                                    sx={{
                                        fontSize: {
                                            xs: '20px',
                                            sm: '24px'
                                        }
                                    }}>
                            Начните планировать своё меню с помощью приложения, чтобы больше не мучать себя
                            вопросом <strong>“что сегодня приготовить?”</strong>.
                        </Typography>

                        <Grid2 container
                               columns={{
                                   xs: 1,
                                   sm: 2
                               }}
                               alignItems="center"
                               mt={2}>
                            <Grid2 xs={1}>
                                <Box component="img"
                                     src="/assets/paywall/illustration1.png"
                                     width="311px"
                                     height="394px"
                                     m={2}/>
                            </Grid2>

                            <Grid2 xs={1}>
                                <Typography variant="body1"
                                            sx={{
                                                fontSize: {
                                                    xs: '20px',
                                                    sm: '24px'
                                                }
                                            }}>
                                    Больше 1000 пользователей уже попробовали CookHaus и убедились, как наше приложение
                                    позволяет экономить время и следить за питанием.
                                    <br/>
                                    <br/>
                                    Присоединяйтесь прямо сейчас и посмотрите, какие рецепты мы подобрали&nbsp;
                                    <strong className="brand-secondary-color">специально для вас!</strong>
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Stack>

                    <Box mt={3}/>
                    <div ref={buyBlockRef} className="pw-secondaryBlock">
                        <Container maxWidth="sm" sx={{p: 0}}>
                            <BuyBlock minutes={minutes} seconds={seconds}/>
                        </Container>
                    </div>
                </Stack>
                <FloatingNextButton minutes={minutes} seconds={seconds} isVisible={isFixedButtonVisible}
                                    refToScroll={buyBlockRef}/>
                <Box mt={3}/>
            </Transitions>
        </AppContainer>
    );
};

export default PaywallPage;