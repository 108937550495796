import React, {useEffect, useState} from "react";
import {ROUTES, useAppNavigate, useOfferCodeFromQuery} from "../helpers/routing";
import {useAppDispatch, useTypedSelector} from "../store/store";
import AppContainer from "../components/appContainer";
import {Alert, AlertTitle, Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import {completeRegistration} from "../api/api";
import {mapAnswersToMenuPreferences} from "../helpers/menuPreferences";
import {sendCompleteRegistration} from "../helpers/analytics";
import {AccountCircle, EmailRounded} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import {setToken} from "../store/accountSlice";
import {OAuthButtons, OAuthType} from "../components/oauthButtons";
import {VkTokenExchangeParams} from "../api/types";
import {Transitions} from "../components/transitions";

interface ButtonBuyProps {
    isLoading: boolean;
}

const NextButton: React.FC<ButtonBuyProps> = ({isLoading}) => {
    if (isLoading) {
        return (
            <Stack direction="row" justifyContent="center" width="100%"><Box component="div" className="dots-flashing"/></Stack>);
    }

    return (
        <Button variant="contained"
                color="secondary"
                sx={{
                    color: 'white',
                    textTransform: 'none',
                    borderRadius: 4
                }}
                size="large"
                type="submit">
            Далее
        </Button>
    );
};

const RegistrationCompleteForm = () => {
    const quizState = useTypedSelector((s) => s.quiz);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const offerCode = useOfferCodeFromQuery();

    const handleSuccessOAuth = async (type: OAuthType, token: string | VkTokenExchangeParams) => {
        console.log(`success sign in ${type}: ${token}`);
        switch (type) {
            case OAuthType.Google:
                await handleSubmit({googleToken: token as string});
                break;
            case OAuthType.Yandex:
                await handleSubmit({yandexToken: token as string});
                break;
            case OAuthType.VK:
                await handleSubmit({vkTokenExchangeData: token as VkTokenExchangeParams});
                break;
        }
    };

    const handleFailOAuth = (type: OAuthType, error: any) => {
        console.error(`fail sign in: ${type}`, error);
    };

    const handleSubmit = async ({name, email, googleToken, yandexToken, vkTokenExchangeData}: {
        name?: string;
        email?: string,
        googleToken?: string,
        yandexToken?: string,
        vkTokenExchangeData?: VkTokenExchangeParams
    }) => {
        setIsLoading(true);
        setHasError(false);

        const registrationResult = await completeRegistration(
            {
                menuPreferences: mapAnswersToMenuPreferences(quizState.answers),
                offerCode,
                authData: {
                    name,
                    email,
                    googleToken,
                    yandexToken,
                    vkTokenExchangeData
                }
            }
        );
        if (registrationResult === null || !registrationResult?.success) {
            setIsLoading(false);
            setHasError(true);
            return;
        }

        setHasError(false);
        sendCompleteRegistration(false);
        dispatch(
            setToken({
                authToken: registrationResult!.result!.accessToken
            })
        );

        navigate(ROUTES.SUBSCRIBE);
    };

    return (
        <Stack
            component="form"
            onSubmit={async (event: any) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const email = formJson.email as string;
                const name = formJson.name as string;
                await handleSubmit({name, email});
            }}>

            {hasError &&
                <Alert variant="filled" severity="error">
                    <AlertTitle>Упс... Что-то пошло не так!</AlertTitle>
                    Попробуйте повторить попытку еще раз.
                </Alert>
            }

            <Typography variant="body1" fontSize="22px">
                Укажите свое имя и email
            </Typography>
            <Box mt={2}/>

            <TextField
                required
                margin="dense"
                id="name2"
                name="name"
                type="text"
                fullWidth
                disabled={isLoading}
                placeholder="Ваше имя *"
                helperText="Нужно для обращения в письмах и приложении."
                variant="outlined"
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                            <AccountCircle/>
                        </InputAdornment>
                    ),
                    sx: {
                        borderRadius: 3
                    }
                }}
            />

            <TextField
                required
                margin="dense"
                id="name"
                name="email"
                disabled={isLoading}
                placeholder="Ваш email *"
                type="email"
                fullWidth
                helperText="Этот Email будет использоваться для авторизации."
                variant="outlined"
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                            <EmailRounded/>
                        </InputAdornment>
                    ),
                    sx: {
                        borderRadius: 3
                    }
                }}
            />

            <Box mt={3}/>
            <NextButton isLoading={isLoading}/>
            <Box mt={2}/>
            <Typography variant="body1" fontSize="22px">
                или быстрая регистрация с помощью
            </Typography>
            <Box mt={2}/>
            <OAuthButtons onSuccess={handleSuccessOAuth} onFailure={handleFailOAuth}/>
            {isLoading &&
                <Stack direction="row" justifyContent="center" width="100%" mt={1}>
                    <Box component="div" className="dots-flashing"/>
                </Stack>
            }
            <Box mt={2}/>
        </Stack>
    );
};

const RegisterAccountPage = () => {
    const accountState = useTypedSelector(state => state.account);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (!!accountState.authToken) {
            navigate(ROUTES.PAY);
        }
    }, [accountState, navigate]);

    return (
        <AppContainer>
            <Transitions key="registerAccount">
                <Typography variant="h4" component="div" mt={2}>
                    Создайте аккаунт
                </Typography>

                <Typography variant="body2" component="p" mt={1} fontSize="16px">
                    Нужно зарегистрироваться, чтобы продолжить.<br/>
                    Для быстрой регистрации вы можете воспользоваться кнопками Google, Yandex, или ВК, либо ввести свою
                    почту и имя самостоятельно.
                </Typography>

                <Box mt={2}/>
                <RegistrationCompleteForm/>
                <Box mt={3}/>
            </Transitions>
        </AppContainer>
    );
};

export default RegisterAccountPage;