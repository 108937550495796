import {NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import type {To} from "@remix-run/router";
import type {NavigateOptions} from "react-router/dist/lib/context";

export const ROUTES = {
    MAIN_PAGE: '/',
    SUBSCRIBE: '/subscribe',
    QUIZ_QUESTION: (num: number) => `/quiz/${num}`,
    LOADING_PLAN: '/loading-plan',
    REGISTER_ACCOUNT: '/register-account',
    OS_SELECT: '/os-select',
    PAY: '/pay'
};

const DEFAULT_OFFER_CODE = "AB_APPWEEK1";

export const useOfferCodeFromQuery = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const offerCode = queryParams.get('oc');
    if (!offerCode) {
        return DEFAULT_OFFER_CODE;
    }

    return offerCode;
};

export const useAppNavigate = (): NavigateFunction => {
    const offerCode = useOfferCodeFromQuery();
    const navigate = useNavigate();

    return (to: To | number, options?: NavigateOptions) => {
        if (typeof to === "number") {
            navigate(to);
            return;
        }

        const path = to as string;
        if (offerCode) {
            const url = new URL(path, window.location.origin);
            const currentParams = new URLSearchParams(url.search);
            currentParams.set("oc", offerCode);
            const updatedPath = `${url.pathname}?${currentParams.toString()}`;
            navigate(updatedPath, options);
        } else {
            navigate(path, options);
        }
    };
};