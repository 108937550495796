import {Transitions} from "../components/transitions";
import React, {useCallback, useState} from "react";
import AppContainer from "../components/appContainer";
import {Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Typography} from "@mui/material";
import {ExpandMoreRounded} from "@mui/icons-material";
import {SelectQuestion} from "./quiz/components/selectQuestion";
import {QuestionOption} from "../store/quizSlice";
import {ROUTES, useAppNavigate} from "../helpers/routing";
import {sendShownQuestion, sendStartedQuiz} from "../helpers/analytics";

const OsSelectPage = () => {
    const ANSWER_YES = "YES";
    const ANSWER_NO = "NO";

    const questionOptions: QuestionOption[] = [
        {
            text: 'Да, есть',
            name: ANSWER_YES
        },
        {
            text: 'Нет, у меня только iPhone',
            name: ANSWER_NO
        }
    ];

    const [hasIosError, setHasIosError] = useState<boolean>();
    const navigate = useAppNavigate();

    const handleNextClick = useCallback((answer: string[]) => {
        if (answer.some(a => a === ANSWER_YES)) {
            sendShownQuestion(1);
            sendStartedQuiz();
            navigate(ROUTES.QUIZ_QUESTION(1));
            return;
        }

        setHasIosError(true);
    }, [navigate]);

    return (
        <Transitions key="osSelect">
            <AppContainer>
                <Typography variant="h1" fontSize="32px">
                    У вас есть смартфон на Android?
                </Typography>

                <Box mt={3}/>
                <SelectQuestion options={questionOptions}
                                isMultiSelect={false}
                                onNextClick={handleNextClick}/>

                {hasIosError &&
                    <Alert severity="error">
                        <AlertTitle>К сожалению, приложение еще недоступно для iOS :(</AlertTitle>
                        Сейчас наше приложение доступно только для Android. Мы работаем над версией для iOS и
                        обязательно
                        сообщим, как только она будет готова!
                        Вы можете следить за обновлениями на нашем сайте: <a
                        href="https://cookhaus.guru">cookhaus.guru</a>.
                    </Alert>
                }

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreRounded/>}>
                        Как понять, что мой смартфон на Android?
                    </AccordionSummary>

                    <AccordionDetails>
                        Посмотрите на заднюю крышку вашего смартфона: если на ней нет логотипа
                        <br/>Apple <img src="/assets/quiz_first/Apple_logo_grey.svg" alt="логотип Apple" width="16px"/>,
                        значит, у вас смартфон на Android.
                    </AccordionDetails>
                </Accordion>
            </AppContainer>
        </Transitions>
    );
};

export default OsSelectPage;