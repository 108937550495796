import Stack from "@mui/material/Stack";
import {Box, Button, Typography} from "@mui/material";
import {ArrowBack, CancelSharp, CheckCircleSharp} from "@mui/icons-material";
import {Link, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {Transitions} from "../components/transitions";
import React, {useEffect, useMemo, useState} from "react";
import {getPaymentStatus} from "../api/api";
import {isAndroidOS} from "../helpers/isAndroid";
import {sendSuccessfulPayment} from "../helpers/analytics";
import {PaymentStatus} from "../api/types";
import AppContainer from "../components/appContainer";
import {useAppNavigate} from "../helpers/routing";

const PaymentSupportBlock: React.FC = () => {
    return (
        <Typography variant="body2" mt={2} color="grey">
            Если у вас возникли проблемы с платежом, вы можете обратиться на email <a
            href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>, либо в телеграм аккаунт <a
            href="https://t.me/cookhaus_support">@cookhaus_support</a>.
        </Typography>
    );
};

const LoadingPaymentStatusPage: React.FC = () => {
    const navigate: ReturnType<typeof useAppNavigate> = useAppNavigate();

    return (
        <AppContainer>
            <Stack direction="column" ml={1} mr={1} alignItems="center">
                <Box mt={5}/>
                <Box component="div" className="dots-flashing"/>
                <Typography variant="body2" mt={2} fontSize="24px">
                    Проверяем данные вашего платежа
                </Typography>

                <Box mt={5}/>
                <Button startIcon={<ArrowBack/>} variant="contained" color="primary" component="a"
                        onClick={() => navigate(-1)}>
                    Вернуться назад
                </Button>
                <PaymentSupportBlock/>
            </Stack>
        </AppContainer>
    );
};

interface InstructionItemProps {
    num: string;
    description: React.ReactNode;
}

const InstructionItem: React.FC<InstructionItemProps> = ({num, description}) => {
    return (
        <Stack direction="row" spacing={2} alignItems="top">
            <Box component="div"
                 sx={{
                     position: 'relative',
                     backgroundColor: '#70B9BE',
                     borderRadius: 3,
                     width: '64px',
                     height: '45px',
                     flexShrink: 0
                 }}>
                <Typography variant="body1" fontSize="30px" fontWeight="bold" color="#fff">
                    {num}
                </Typography>
            </Box>

            <Typography variant="body1" fontSize="18px" textAlign="left" component="div">
                {description}
            </Typography>
        </Stack>
    );
};

export const AndroidInstruction = () => {
    return (
        <Stack direction="column" spacing={2}>
            <InstructionItem num="1"
                             description={
                                 <>
                                     <Stack direction="column">
                                         <Box component="img"
                                              src="https://static.tildacdn.com/tild3332-3139-4864-b961-343762323336/b73100ed404f4951bad2.png"
                                              width="150px"
                                              height="150px"/>
                                         Установите приложение CookHaus из магазина приложений GooglePlay по
                                         <a href="https://redirect.appmetrica.yandex.com/serve/1181859421366530486">ссылке,</a> либо отсканируйте QR-код.
                                     </Stack>
                                 </>
                             }/>

            <InstructionItem num="2"
                             description={
                                 <>
                                     После скачивания приложения откройте его, и нажмите на кнопку <b>"У меня уже
                                     есть
                                     аккаунт"</b>.
                                 </>
                             }/>

            <InstructionItem num="3"
                             description={
                                 <>
                                     Введите свой email, который указали при покупке, либо авторизуйтесь с помощью
                                     Google, Яндекс, ВК.<br/>
                                     <strong>Важно!</strong> Авторизоваться нужно тем же методом, с которым
                                     регистрировались здесь раннее. Например,
                                     если вы воспользовались быстрым созданием аккаунта с помощью Яндекс, значит, в
                                     приложении также нужно войти через Яндекс.
                                 </>
                             }/>

            <InstructionItem num="4"
                             description={
                                 <>
                                     Если вы указали Email, на указанный вами email мы отправим код подтверждения. Его
                                     нужно будет
                                     ввести в
                                     появившемся окне.
                                 </>
                             }/>

            <InstructionItem num="5"
                             description={
                                 <>
                                     Приятного использования! Если возникли вопросы или проблемы, обращайтесь на
                                     почту <a
                                     href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>
                                 </>
                             }/>

            <Typography variant="body2" mt={3} fontSize="15px" color="grey">
                Эта инструкция также отправлена на ваш email.
            </Typography>
        </Stack>
    );
};

const WebMyInstruction: React.FC = () => {
    return (
        <Stack direction="column" spacing={3}>
            <InstructionItem num="1"
                             description={
                                 <>
                                     Перейдите в личный кабинет по <a rel="noreferrer" target="_blank"
                                                                      href="https://my.cookhaus.guru/">ссылке</a>.
                                 </>
                             }/>

            <InstructionItem num="2"
                             description={
                                 <>
                                     Введите свой email, который использовали при покупке.
                                 </>
                             }/>

            <InstructionItem num="3"
                             description={
                                 <>
                                     На указанный вами email мы отправим код подтверждения. Его нужно будет
                                     ввести в
                                     появившемся окне.
                                 </>
                             }/>

            <InstructionItem num="4"
                             description={
                                 <>
                                     Приятного использования! Если возникли вопросы или проблемы, обращайтесь на
                                     почту <a
                                     href="mailto:support@cookhaus.guru">support@cookhaus.guru</a>
                                 </>
                             }/>

            <Typography variant="body2" mt={3} fontSize="15px" color="grey">
                Эта инструкция также отправлена на ваш email, указанный при оплате.
            </Typography>
        </Stack>
    );
};

interface SuccessPaymentStatusPageProps {
    isAndroid: boolean;
}

const SuccessPaymentStatusPage: React.FC<SuccessPaymentStatusPageProps> = ({isAndroid}) => {
    return (
        <AppContainer>
            <Stack direction="column" ml={1} mr={1} alignItems="center">
                <Box mt={5}/>
                <CheckCircleSharp color="success" sx={{fontSize: '96px'}}/>
                <Typography variant="body2" fontSize="26px" fontWeight="bold">
                    Спасибо за покупку!
                </Typography>

                <Typography variant="body2" mt={1} fontSize="18px">
                    Ваш платеж успешно принят.
                </Typography>

                <Typography variant="h2" mt={3} fontSize="24px" fontWeight="bold">
                    Что дальше?
                </Typography>

                <Box mt={2}/>

                {
                    isAndroid
                        ? <AndroidInstruction/>
                        : <WebMyInstruction/>
                }

            </Stack>
        </AppContainer>
    );
};

const FailedPaymentStatusPage: React.FC = () => {
    return (
        <AppContainer>
            <Stack direction="column" ml={1} mr={1} alignItems="center">
                <Box mt={5}/>
                <CancelSharp color="error" sx={{fontSize: '96px'}}/>
                <Typography variant="body2" fontSize="26px" fontWeight="bold">
                    Произошла ошибка :(
                </Typography>

                <Typography variant="body2" mt={3} fontSize="18px">
                    К сожалению, ваш платеж отклонен. Мы не знаем, почему это произошло, однако вы можете попробовать
                    оплатить еще раз.
                </Typography>

                <Box mt={3}/>
                <Button startIcon={<ArrowBack/>} variant="contained" color="primary" component={Link} to="/order-plan">
                    Назад
                </Button>
                <Box mt={1}/>
                <PaymentSupportBlock/>
            </Stack>
        </AppContainer>
    );
};

const EndPaymentPage: React.FC = () => {
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.Initialized);
    const location = useLocation();
    const isAndroid = useMemo<boolean>(() => isAndroidOS(), []);

    const queryParams: URLSearchParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get("paymentId");

    useEffect(() => {
        if (!paymentId) {
            return;
        }

        let iterations: number = 0;
        const interval = setInterval(async () => {
            iterations++;
            const payment = await getPaymentStatus(paymentId);
            if (payment && (payment.status === PaymentStatus.Paid || payment.status === PaymentStatus.Failed)) {
                clearInterval(interval);
                setPaymentStatus(payment.status);

                if (payment.status === PaymentStatus.Paid && !localStorage.getItem(localStorageKeyForMetrika(paymentId))) {
                    sendSuccessfulPayment(payment.amount);
                    localStorage.setItem(localStorageKeyForMetrika(paymentId), "true");
                }
            } else if (iterations > 10) {
                setPaymentStatus(PaymentStatus.Failed);
                clearInterval(interval);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [paymentId]);

    return (
        <AnimatePresence>
            <Transitions key="payment-status">
                {paymentStatus === PaymentStatus.Paid &&
                    <SuccessPaymentStatusPage key="success" isAndroid={isAndroid}/>}
                {paymentStatus === PaymentStatus.Failed && <FailedPaymentStatusPage key="failed"/>}
                {paymentStatus !== PaymentStatus.Paid && paymentStatus !== PaymentStatus.Failed &&
                    <LoadingPaymentStatusPage key="loading"/>}
            </Transitions>
        </AnimatePresence>
    );
};

const localStorageKeyForMetrika = (paymentId: string | null): string => {
    return `${paymentId}_metrika`;
}

export default EndPaymentPage;